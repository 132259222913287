@import url(https://fonts.googleapis.com/css2?family=Roboto&display=swap);
html {
  font-size: 16px;
}

body {
  margin: 0;
  background-color: #191919;
}

.input {
  text-align: right;
  height: 4rem;
}

.input input {
  background-color: #444444;
  border: none;
  outline: none;
  color: white;
  font-size: 1.3rem;
  border-radius: .4rem;
  padding-top: .3rem;
  padding-bottom: .3rem;
  padding-left: .6rem;
  padding-right: .6rem;
}
.step {
  height: 4rem;
}

.step-next {
  margin-top: 1rem;
}

.compute-next {
  border-radius: .4rem;
}

.description {
  text-align: right;
  margin-top: 1rem;
  height: 4rem;
}
.error {
  color: #CC0B00;
  position: fixed;
  bottom: 2rem;
  left: 2rem;
  font-size: 1.5rem;
}
.solver {
  width: 100%;
  margin-top: 20vh;
  display: flex;
  flex-direction: row;
  grid-gap: 2rem;
  gap: 2rem;
  font-size: 1.5rem;
  color: white;
  font-family: 'Roboto', sans-serif;
}

.solver-left, .solver-right {
  flex: 1 1;
}

