@import url('https://fonts.googleapis.com/css2?family=Roboto&display=swap');
.solver {
  width: 100%;
  margin-top: 20vh;
  display: flex;
  flex-direction: row;
  gap: 2rem;
  font-size: 1.5rem;
  color: white;
  font-family: 'Roboto', sans-serif;
}

.solver-left, .solver-right {
  flex: 1;
}