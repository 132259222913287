.step {
  height: 4rem;
}

.step-next {
  margin-top: 1rem;
}

.compute-next {
  border-radius: .4rem;
}
